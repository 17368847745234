import React from 'react';
import {
    Tooltip,
} from "antd";
// Icon
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
// Micro

/**
 * Initially yes
 * Once data received, then would switch to either no/yes
 * @param {} vehicle 
 */
const MobilizerIconDefiner = (vehicle) => {

    const NoMobilizerSupportTitle = (
        <>
            <p>{`No Mobilizer Support`}</p>
        </>
    )

    if(!vehicle) 
        return (
            <Tooltip 
                placement="top" 
                title={NoMobilizerSupportTitle}
            >
                    <DirectionsCarIcon sx={{ fontSize: 20, color: "black" }}/>
            </Tooltip>
        )
    else {
        const {
            immo
        } = vehicle

        const ImmobilizerTitle = (
            <>
                <p>{`Vehicle is immobilized`}</p>
            </>
        )

        const MobilizerTitle = (
            <>
                <p>{`Vehicle is mobilized`}</p>
            </>
        )

        if(immo) {
            return (
                <Tooltip 
                    placement="top" 
                    title={ImmobilizerTitle}
                >
                        <DirectionsCarIcon sx={{ fontSize: 20, color: "orange" }}/>
                </Tooltip>
            )
        } else {
            return (
                <Tooltip 
                    placement="top" 
                    title={MobilizerTitle}
                >
                        <DirectionsCarIcon sx={{ fontSize: 20, color: "green" }}/>
                </Tooltip>
            )
        }
    }
}

const MobilizerIcon = ({vehicle}) => {
    return <>
        {
            MobilizerIconDefiner(vehicle)
        }
    </>
}

export default MobilizerIcon