import React from 'react';
import {
    Tooltip,
} from "antd";
// Icon
import SignalCellularOffRoundedIcon from '@mui/icons-material/SignalCellularOffRounded';
import SignalCellular0BarRoundedIcon from '@mui/icons-material/SignalCellular0BarRounded';
import SignalCellular1BarRoundedIcon from '@mui/icons-material/SignalCellular1BarRounded';
import SignalCellular2BarRoundedIcon from '@mui/icons-material/SignalCellular2BarRounded';
import SignalCellular3BarRoundedIcon from '@mui/icons-material/SignalCellular3BarRounded';
import SignalCellular4BarRoundedIcon from '@mui/icons-material/SignalCellular4BarRounded';
// Micro
import {
    EventStatusIndividualIcon
} from '../micro'

/**
 * Initially yes
 * Once data received, then would switch to either no/yes
 * @param {} vehicle 
 */
const CellSignalIconDefiner = (vehicle) => {
    if(!vehicle) 
        return (
            <EventStatusIndividualIcon
                icon='cellNo'
                size={20}
                color="red"
                title={
                    <>
                        <p>{`No Cellular Coverage`}</p>
                    </>
                }
            />
        )
    else if (!Object.prototype.hasOwnProperty.call(vehicle, 'cell_signal')) {
        const {
            connectivity
        } = vehicle

        switch(connectivity) {
            case 1: {
                return (
                    <EventStatusIndividualIcon
                        icon='cellFull'
                        size={20}
                        color="green"
                        title={'Has Cellular Coverage'}
                    />
                )
            }
            case 0:
            default: {
                return (
                    <EventStatusIndividualIcon
                        icon='cellNo'
                        size={20}
                        color="red"
                        title={
                            <>
                                <p>{`No Cellular Coverage`}</p>
                            </>
                        }
                    />
                )
            }
        }

    } else {
        const {
            cell_signal,
            connectivity
        } = vehicle

        if(!connectivity) 
            return (
                <Tooltip 
                    placement="top" 
                    title={
                        <>
                            <p>{`No Cellular Coverage`}</p>
                        </>
                    }
                >
                        <SignalCellularOffRoundedIcon sx={{ fontSize: 20, color: "red" }}/>
                </Tooltip>
            )
        else {

            const CellSignalTitle = (
                <>
                    <p>{`Has Cellular Coverage with Strength of ${cell_signal} out of 5`}</p>
                </>
            )
    
            switch(cell_signal) {
                case 5:
                case 4: {
                    return (
                        <Tooltip 
                            placement="top" 
                            title={CellSignalTitle}
                        >
                            <SignalCellular4BarRoundedIcon sx={{ fontSize: 20, color: "green" }}/>
                        </Tooltip> 
                    )
                }
                case 3: {
                    return (
                        <Tooltip 
                            placement="top" 
                            title={CellSignalTitle}
                        >
                            <SignalCellular3BarRoundedIcon sx={{ fontSize: 20, color: "green" }}/>
                        </Tooltip> 
                    )
                }
                case 2: {
                    return (
                        <Tooltip 
                            placement="top" 
                            title={CellSignalTitle}
                        >
                            <SignalCellular2BarRoundedIcon sx={{ fontSize: 20, color: "green" }}/>
                        </Tooltip>
                    )
                }
                case 1: {
                    return (
                        <Tooltip 
                            placement="top" 
                            title={CellSignalTitle}
                        >
                            <SignalCellular1BarRoundedIcon sx={{ fontSize: 20, color: "green" }}/>
                        </Tooltip>
                    )
                }
                case 0:
                default: {
                    return (
                        <Tooltip 
                            placement="top" 
                            title={CellSignalTitle}
                        >
                            <SignalCellular0BarRoundedIcon sx={{ fontSize: 20, color: "green" }} />
                        </Tooltip>
                    )
                }
            }
        }
    }
}

const CellSignalIcon = ({vehicle}) => {
    return <>
        {
            CellSignalIconDefiner(vehicle)
        }
    </>
}

export default CellSignalIcon