import * as ActionTypes from "../action-types";

export const set_map_control = (control) => {
    return {
      type: ActionTypes.SET_MAP_CONTROL,
      control: control
    }
  }
  
  export const set_map_location = (location) => {
    return {
      type: ActionTypes.SET_MAP_LOCATION,
      location: location
    }
  }
  
  export const reset_map_location = (location) => {
    return {
      type: ActionTypes.RESET_MAP_LOCATION,
      location: location
    }
  }
  
  export const set_map_zoom = (zoom) => {
    return {
      type: ActionTypes.SET_MAP_ZOOM,
      zoom: zoom
    }
  }
  
  export const set_new_geofence_from_map = (geofence) => {
    return {
      type: ActionTypes.SET_NEW_GEOFENCE,
      geofence: geofence
    }
  }
  
  export const clear_new_geofence_from_map = () => {
    return {
      type: ActionTypes.CLEAR_GEOFENCE
    }
  }
  
  export const set_selected_geofence_templates_for_map = (geofenceTemplateList) => {
    // console.log("Geofence Template List:", geofenceTemplateList);
  
    return {
      type: ActionTypes.SET_SELECTED_GEOFENCE_TEMPLATES,
      geofenceTemplateList,
    }
  }
  
  export const clear_selected_geofence_templates_for_map = () => {
    return {
      type: ActionTypes.CLEAR_SELECTED_GEOFENCE_TEMPLATES,
    }
  }
  
  export const add_map_shape_into_list = (drawnShape) => {
    return {
      type: ActionTypes.ADD_MAP_SHAPE_INTO_LIST,
      drawnShape: drawnShape
    }
  }
  
  export const clear_map_shapes_from_list = () => {
    return {
      type: ActionTypes.CLEAR_MAP_SHAPES_FROM_LIST,
    }
  }
  
  export const get_browser_latlng = () => {
    if('geolocation' in window.navigator) {
      window.navigator.geolocation.getCurrentPosition(position => {
        // console.log("coord", position.coords);
  
        return {
          type: ActionTypes.GET_BROWSER_LOC,
          location: {lat: position.coords.latitude,lng: position.coords.longitude}
        }
      })
    }
  }
  
  export const set_map_filters = (durationMin, selectedEventTypes, selectedTransitTypes) => {
    // console.log("Setting Map Filters:", durationMin, selectedEventTypes, selectedTransitTypes);
  
    return {
      type: ActionTypes.SET_MAP_FILTERS,
      durationMin: durationMin,
      selectedEventTypes: selectedEventTypes,
      selectedTransitTypes: selectedTransitTypes,
    }
  }
  
  export const add_enabled_vehicle_pop_up = (enabledIdentifier) => {
    return {
      type: ActionTypes.ADD_ENABLED_VEHICLE_POP_UP,
      enabledIdentifier: enabledIdentifier
    }
  }
  
  export const remove_enabled_vehicle_pop_up = (enabledIdentifier) => {
    return {
      type: ActionTypes.REMOVE_ENABLED_VEHICLE_POP_UP,
      enabledIdentifier: enabledIdentifier
    }
  }
  
  export const clear_enabled_vehicle_pop_ups = () => {
    return {
      type: ActionTypes.CLEAR_ENABLED_VEHICLE_POP_UP,
    }
  }
  
  export const add_enabled_event_pop_up = (enabledIdentifier) => {
    return {
      type: ActionTypes.ADD_ENABLED_EVENT_POP_UP,
      enabledIdentifier: enabledIdentifier
    }
  }
  
  export const remove_enabled_event_pop_up = (enabledIdentifier) => {
    return {
      type: ActionTypes.REMOVE_ENABLED_EVENT_POP_UP,
      enabledIdentifier: enabledIdentifier
    }
  }
  
  export const clear_enabled_event_pop_ups = () => {
    return {
      type: ActionTypes.CLEAR_ENABLED_EVENT_POP_UPS,
    }
  }
  
  export const add_enabled_geofence_pop_up = (enabledIdentifier) => {
    return {
      type: ActionTypes.ADD_ENABLED_GEOFENCE_POP_UP,
      enabledIdentifier: enabledIdentifier
    }
  }
  
  export const clear_enabled_geofence_pop_ups = () => {
    return {
      type: ActionTypes.CLEAR_ENABLED_GEOFENCE_POP_UP,
    }
  }
  
  export const set_geofence_filter_string = () => {
    return {
      type: ActionTypes.SET_NEW_GEOFENCE_FILTER_STRING,
    }
  }
  
  export const reset_geofence_filter_string = () => {
    return {
      type: ActionTypes.RESET_GEOFENCE_FILTER_STRING,
    }
  }
  
  export const enable_show_vehicle_info = () => {
    return {
      type: ActionTypes.ENABLE_SHOW_VEHICLE_INFO,
    }
  }
  
  export const disable_show_vehicle_info = () => {
    return {
      type: ActionTypes.DISABLE_SHOW_VEHICLE_INFO,
    }
  }
  
  export const enable_show_geofence_info = () => {
    return {
      type: ActionTypes.ENABLE_SHOW_GEOFENCE_INFO,
    }
  }
  
  export const disable_show_geofence_info = () => {
    return {
      type: ActionTypes.DISABLE_SHOW_GEOFENCE_INFO,
    }
  }
  
  export const enable_show_geofences = () => {
    return {
      type: ActionTypes.ENABLE_SHOW_GEOFENCES,
    }
  }
  
  export const disable_show_geofences = () => {
    return {
      type: ActionTypes.DISABLE_SHOW_GEOFENCES,
    }
  }
  
  export const enable_show_live_traffic = () => {
    return {
      type: ActionTypes.ENABLE_SHOW_LIVE_TRAFFIC,
    }
  }
  
  export const disable_show_live_traffic = () => {
    return {
      type: ActionTypes.DISABLE_SHOW_LIVE_TRAFFIC,
    }
  }

  export const enable_show_highway_suv = () => {
    return {
      type: ActionTypes.ENABLE_SHOW_HIGHWAY_SUV
    }
  }

  export const disable_show_highway_suv = () => {
    return {
      type: ActionTypes.DISABLE_SHOW_HIGHWAY_SUV
    }
  }

  export const enable_show_highway_suv_info = () => {
    return {
      type: ActionTypes.ENABLE_SHOW_HIGHWAY_SUV_INFO
    }
  }

  export const disable_show_highway_suv_info = () => {
    return {
      type: ActionTypes.DISABLE_SHOW_HIGHWAY_SUV_INFO
    }
  }

  export const autofocus_2_highway_suv = () => {
    return {
      type: ActionTypes.AUTOFOCUS_HIGHWAY_SUV
    }
  }

  export const disable_autofocus_2_highway_suv = () => {
    return {
      type: ActionTypes.DISABLE_AUTOFOCUS_HIGHWAY_SUV
    }
  }
  
  export const enable_show_vehicle_trail = () => {
    return {
      type: ActionTypes.ENABLE_SHOW_VEHICLE_TRAIL,
    }
  }
  
  export const disable_show_vehicle_trail = () => {
    return {
      type: ActionTypes.DISABLE_SHOW_VEHICLE_TRAIL,
    }
  }
  
  export const reset_ui_control = () => {
    return {
      type: ActionTypes.RESET_UI_CONTROL,
    }
  }

// Map Event 

export const set_map_focuse_detail_point_type_request = (pointType) => dispatch => {
  dispatch(set_map_focuse_detail_point_type_success(pointType))
}

const set_map_focuse_detail_point_type_success = (pointType) => {
  return {
    type: ActionTypes.SET_MAP_FOCUS_DETAIL_POINT_TYPE,
    pointType
  }
}

export const focus_on_map_detail_point_request = () => dispatch => {
  dispatch(focus_on_map_detail_point_success())
}

const focus_on_map_detail_point_success = () => {
  return {
    type: ActionTypes.FOCUS_ON_MAP_DETAIL_POINT
  }
}

export const unfocus_on_map_detail_point_request = () => dispatch => {
  dispatch(unfocus_on_map_detail_point_success())
}

const unfocus_on_map_detail_point_success = () => {
  return {
    type: ActionTypes.UNFOCUS_ON_MAP_DETAIL_POINT
  }
}

export const open_transit_record_drawer_on_map_request = () => dispatch => {
  dispatch(open_transit_record_drawer_on_map_success())
}

const open_transit_record_drawer_on_map_success = () => {
  return {
    type: ActionTypes.OPEN_TRANSIT_DRAWER_ON_MAP
  }
}

export const close_transit_record_drawer_on_map_request = () => dispatch => {
  dispatch(close_transit_record_drawer_on_map_success())
}

const close_transit_record_drawer_on_map_success = () => {
  return {
    type: ActionTypes.CLOSE_TRANSIT_DRAWER_ON_MAP
  }
}