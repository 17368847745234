const REDUX_DEVICE_BATCH_GET_SIZE = 100
const REDUX_DEVICE_GROUP_BATCH_GET_SIZE = 100
const REDUX_GEOFENCE_BATCH_GET_SIZE = 100
const REDUX_GEOFENCE_TEMPLATE_BATCH_GET_SIZE = 100
const REDUX_RULE_BATCH_GET_SIZE = 100
const REDUX_RULE_TEMPLATE_BATCH_GET_SIZE = 100
const REDUX_VEHICLE_BATCH_GET_SIZE = 100
const REDUX_VEHICLE_GROUP_BATCH_GET_SIZE = 100

// Maintenance Management Module
const REDUX_MAINTENANCE_TEMPLATE_BATCH_GET_SIZE = 100
const REDUX_MAINTENANCE_RULE_BATCH_GET_SIZE = 100
const REDUX_MAINTENANCE_COMPONENT_BATCH_GET_SIZE = 100
const REDUX_MAINTENANCE_SERVICE_BATCH_GET_SIZE = 100

const REDUX_CRUD_API_ACTION = {
    CREATE: 'C',
    READ: 'R',
    UPDATE: 'U',
    DELETE: 'D',
    EDIT: 'U'
}

const REDUX_CRUD_API_ACTION_THRU_CODE = {
    C: 'CREATE',
    R: 'READ',
    U: 'UPDATE',
    D: 'DELETE'
}

export {
    REDUX_DEVICE_BATCH_GET_SIZE,
    REDUX_DEVICE_GROUP_BATCH_GET_SIZE,
    REDUX_GEOFENCE_BATCH_GET_SIZE,
    REDUX_GEOFENCE_TEMPLATE_BATCH_GET_SIZE,
    REDUX_RULE_BATCH_GET_SIZE,
    REDUX_RULE_TEMPLATE_BATCH_GET_SIZE,
    REDUX_VEHICLE_BATCH_GET_SIZE,
    REDUX_VEHICLE_GROUP_BATCH_GET_SIZE,
    // Maintenance Management Module
    REDUX_MAINTENANCE_TEMPLATE_BATCH_GET_SIZE,
    REDUX_MAINTENANCE_RULE_BATCH_GET_SIZE,
    REDUX_MAINTENANCE_COMPONENT_BATCH_GET_SIZE,
    REDUX_MAINTENANCE_SERVICE_BATCH_GET_SIZE,
    REDUX_CRUD_API_ACTION,
    REDUX_CRUD_API_ACTION_THRU_CODE
}