import { notification } from 'antd'

notification.config({
    placement: 'bottomRight',
    bottom: 50,
    duration: 60,
    maxCount: 3
});

export const NotifySuccess = (message, description) => notification.success({
    message,
    description,
    placement: `bottomRight`,
})

export const NotifyInfo = (message, description) => notification.info({
    message,
    description,
    placement: `bottomRight`,
})

export const NotifyWarn = (message, description) => notification.warn({
    message,
    description,
    placement: `bottomRight`,
})