import React from 'react'
import {
    Descriptions
} from 'antd'

// Components
import GeoLocation from '../../../GeoLocation'

// Services
import { TIME } from '../../../../services/util'

const {
    parseTime,
} = TIME

const TraceInformation = (props) => {

    const {
        trace
    } = props

    return (
        <>
            <div
                style = {{
                    height: 450,
                    paddingTop: 10,
                    overflow: 'scroll',
                }}
            >
                <Descriptions
                    bordered
                    size = {'small'}
                    column = {1}
                >
                    <Descriptions.Item label = {`Created At`}>
                        {parseTime(trace.vehicleTime)}
                    </Descriptions.Item>

                    <Descriptions.Item label = {`Location`}>
                        <GeoLocation location={trace.location}/>
                    </Descriptions.Item>

                    <Descriptions.Item label = {`Connectivity Status`}>
                        {trace.connectivity}
                    </Descriptions.Item>

                    <Descriptions.Item label = {`GPS Status`}>
                        {trace.gnss_status}
                    </Descriptions.Item>

                    <Descriptions.Item label = {`Cell Strength`}>
                        {trace.cell_signal}
                    </Descriptions.Item>

                    <Descriptions.Item label = {`Engine Ignition`}>
                        {trace.ignition}
                    </Descriptions.Item>

                    <Descriptions.Item label = {`Speed`}>
                        {trace.speed || 0} km/h
                    </Descriptions.Item>

                    <Descriptions.Item label = {`Altitude`}>
                        {trace.altitude}
                    </Descriptions.Item>

                    <Descriptions.Item label = {`Heading`}>
                        {trace.heading} degree
                    </Descriptions.Item>

                    <Descriptions.Item label = {`External Voltage`}>
                        {trace.pwr_ext_v} V
                    </Descriptions.Item>

                </Descriptions>
            </div>
        </>
    )
}

export default TraceInformation