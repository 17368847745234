import React from 'react'
import { 
    useDispatch,
    useSelector
} from 'react-redux';
import { useNavigate } from 'react-router'
import {
    InfoWindow,
    OverlayView,
} from '@react-google-maps/api';

// Constans
import { 
    TEMPLATE,
    ROUTES
} from '../../../../constants'

// Components
import EventStatusIcon from "../../../EventStatusIcon"
import VehiclePlateECUTag from '../../../VehiclePlateECUTag'
import GeoAddress from '../../../GeoAddress'
import { CCBTN } from '../../../CCBTN'

// Handlers
import {
    vehicles_handler,
    report_handler
} from '../../../../handlers'

// Services

// Redux Actions
import {
    v3_mapControl
} from '../../../../services/redux/actions'

const {
    VEHICLE_TRANSIT_STATUS_OBJECT,
    VEHICLE_TRANSIT_STATUS_COLOR
} = TEMPLATE.STATUS

const {
    showVehicleStateLastUpdated_DateTime,
    showVehicleTransitStatusChangeDuration 
} = vehicles_handler

const {
    DERIVATIVE_DATA_TYPE,
    MILEAGE_TYPE,
    speedSelector,
    mileageSelector
} = report_handler

const {
    set_new_geofence_from_map,
    remove_enabled_vehicle_pop_up
} = v3_mapControl

const VehicleInformationBox = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const mapControl = useSelector(state => state.containers.v3.mapControl);

    const {
        thisVehicle,
        thisVehicleStatic,
        paddingInt
    } = props

    return (
        <InfoWindow
            zIndex = {1000}
            options = {{ disableAutoPan: true }}
            mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
            position = {{
                lat: parseFloat(thisVehicle.location.lat),
                lng: parseFloat(thisVehicle.location.lon)
            }}
            onCloseClick = {() => {
                dispatch(remove_enabled_vehicle_pop_up(thisVehicle.vid));
            }}
        >
            <div 
                style = {{ 
                    width: 250 + paddingInt,
                    padding: paddingInt,
                }}
            >
                <h2>
                    <VehiclePlateECUTag
                        plate={thisVehicleStatic.displayName}
                        vehicle={thisVehicle}
                    />
                </h2>

                <p
                    style = {{
                        color: thisVehicle.transitStatus ?  VEHICLE_TRANSIT_STATUS_COLOR[thisVehicle.transitStatus] :  VEHICLE_TRANSIT_STATUS_COLOR["NULL"]
                    }}
                >
                    {
                        showVehicleTransitStatusChangeDuration(thisVehicle)
                    }
                </p>

                <p>
                    {showVehicleStateLastUpdated_DateTime(thisVehicle)}
                </p>
                
                {<p>Speed: {`${thisVehicle.transitStatus === VEHICLE_TRANSIT_STATUS_OBJECT.MOVING ? ( speedSelector(thisVehicle, DERIVATIVE_DATA_TYPE.STATE).toFixed(1) ) || '0.0' : "0.0"} km/h`}</p>}
                {<p>Historical Mileage: {`${mileageSelector(thisVehicle, MILEAGE_TYPE.ODOMETER).toFixed(3)} km`}</p>}

                {
                    thisVehicle.transitStatus !== VEHICLE_TRANSIT_STATUS_OBJECT.DISCONNECTED &&
                        <div style = {{ marginBottom: 5 }}>
                            <GeoAddress 
                                vid={thisVehicle.vid}
                                keyIndex={0}
                            />
                        </div>
                }

                <br />

                <div
                    style = {{
                        display: "flex",
                        marginBottom: 5,
                    }}
                >
                    <EventStatusIcon vehicleID = {thisVehicle.vid}/>
                    {
                        Object.prototype.hasOwnProperty.call(thisVehicleStatic, 'immoCC') ?
                        (
                            <CCBTN.MobilizerBTN immo={thisVehicle.immo} vid={thisVehicle.vid}/>
                        )
                        : 
                        (
                            <></>
                        )
                    }
                </div>

                <div
                    style = {{
                        display: "flex",

                        alignItems: "center",
                        justifyContent: "center",

                        width: "100%",
                    }}
                >
                    <span
                        className = "ButtonLink"
                        aria-hidden="true"
                        onClick = {() => {
                            const currCoor = {
                                lat: parseFloat(thisVehicle.location.lat),
                                lng: parseFloat(thisVehicle.location.lon)
                            }

                            dispatch(set_new_geofence_from_map({
                                ...mapControl.geofence,
                                coordinates: [currCoor],
                            }));

                            navigate(ROUTES.MODULE_ROUTE.GEOFENCE_POI_CREATION, { 'coordinates': [currCoor] });
                        }}
                        style = {{
                            flex: 1,
                            fontSize: 12,
                        }}
                    >
                        Add as POI
                    </span>

                    <a
                        className = "ButtonLink"
                        target = "_blank"
                        rel = "noopener noreferrer"
                        href = {`https://www.google.com.my/maps/place/${thisVehicle.location.lat},${thisVehicle.location.lon}`}
                        style = {{
                            flex: 1,
                            fontSize: 12,
                        }}
                    >
                        View on Map
                    </a>
                </div>
            </div>
        </InfoWindow>
    )
}

export default React.memo(VehicleInformationBox)