import { batch } from 'react-redux';

// Constant
import { TEMPLATE } from '../../../constants';

// Redux
import { 
    setLoadingRoutePlayback, 
    unsetLoadingRoutePlayback,
    setLoadingVehicleReport,
    unsetLoadingVehicleReport
} from './style'
import { get_transit_record_by_timeframe_request } from './transitRecords'
import { get_trip_record_by_timeframe_request } from './tripRecords'
import { 
    get_vehicle_log_by_timeframe_request,
    get_vehicle_derivative_log_by_timeframe_request,
    get_vehicle_event_log_by_timeframe_request,
    get_vehicle_geo_state_log_by_timeframe_request
} from './vehicleLogs'
import { get_vehicle_report_daily_summary_by_timeframe_request } from './vehicleReports'
import { increase_vehicle_report_api_usage_request } from './utils'

const {
    REPORT
} = TEMPLATE

export const get_trip_route_playback_by_vid_request = (vid, startTime, endTime) => (dispatch) => {

    dispatch(setLoadingRoutePlayback())

    const funcPromise = [
        dispatch(get_transit_record_by_timeframe_request(vid, startTime, endTime)),
        dispatch(get_trip_record_by_timeframe_request(vid, startTime, endTime)),
        dispatch(get_vehicle_log_by_timeframe_request(vid, startTime, endTime)),
        dispatch(get_vehicle_event_log_by_timeframe_request(vid, startTime, endTime))
    ]

    return Promise.all(funcPromise)
    .finally(() => dispatch(unsetLoadingRoutePlayback()))
}

export const get_vehicle_report_multi_params_request = (report_type, vids, startTime, endTime) => dispatch => {

    dispatch(setLoadingVehicleReport())

    const funcPromise = []

    vids.map(vid => {

        switch(report_type) {

            case REPORT.VEHICLE_REPORT_TABLE_TYPE_KEYS.TRIP: {

                funcPromise.push(
                    ...[
                        dispatch(get_trip_record_by_timeframe_request(vid, startTime, endTime)),
                        dispatch(get_vehicle_geo_state_log_by_timeframe_request(vid, startTime, endTime)) 
                    ]
                )

                break;
            }
            case REPORT.VEHICLE_REPORT_TABLE_TYPE_KEYS.TRANSIT: {

                funcPromise.push(
                    ...[
                        dispatch(get_transit_record_by_timeframe_request(vid, startTime, endTime)),
                        dispatch(get_vehicle_geo_state_log_by_timeframe_request(vid, startTime, endTime))
                    ]
                )

                break;
            }
            case REPORT.VEHICLE_REPORT_TABLE_TYPE_KEYS.DETAIL: {

                funcPromise.push(
                    ...[
                        dispatch(get_vehicle_log_by_timeframe_request(vid, startTime, endTime)),
                        dispatch(get_vehicle_derivative_log_by_timeframe_request(vid, startTime, endTime)),
                        dispatch(get_vehicle_geo_state_log_by_timeframe_request(vid, startTime, endTime))
                    ]
                )

                break;
            }
            case REPORT.VEHICLE_REPORT_TABLE_TYPE_KEYS.DAILY_SUMMARY : {

                funcPromise.push(
                    ...[
                        dispatch(get_vehicle_report_daily_summary_by_timeframe_request(vid, startTime, endTime))
                    ]
                )

                break;
            }
            default: {
                break;
            }

        }
    })

    return Promise.all(funcPromise)
    .finally(() => batch(() => {
        dispatch(unsetLoadingVehicleReport())
        dispatch(increase_vehicle_report_api_usage_request())
    }))

}