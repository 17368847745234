import React from 'react';
import {
    Tooltip,
} from "antd";

// Constants
import { TEMPLATE } from '../../../constants';

const {
    EVENT_SVG
} = TEMPLATE.STATUS

export const EventStatusIndividualIcon = ({ title, icon, color, size, viewBox }) => {
    const ICON = EVENT_SVG[icon];

    return ICON ?
        (
            <div>
                <Tooltip placement="top" title={title}>
                    <ICON height={size} width={size} fill={color} viewBox={viewBox || '0 0 24 24'} />
                </Tooltip>
            </div>
        ) :
        null
}