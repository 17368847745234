import React from 'react'

import {
	// BellOutlined,
	CopyOutlined,
	ForkOutlined,
    InsuranceOutlined,
	TeamOutlined,
	UserOutlined,
	GatewayOutlined,
	SettingOutlined,
	// SnippetsOutlined,
    AreaChartOutlined,
	UnorderedListOutlined,
    VideoCameraOutlined,
    // Thiry Party
    CodepenOutlined
} from '@ant-design/icons';

import { FEATURE_NAME } from '../common/features'

// Services
export const FEATURES = FEATURE_NAME

export const FEATURE_LABEL = {
    // Management
    [FEATURE_NAME.GEOFENCE_MANAGEMENT]: 'Geofence Management',
    [FEATURE_NAME.USER_MANAGEMENT]: "User Management",
    [FEATURE_NAME.OVERALL_MANAGEMENT]: 'Overall Management',
    // Highway Surveillance Management
    [FEATURE_NAME.HIGHWAY_SURVEILLANCE_MANAGEMENT]: 'Highway Surveillance Management',
    // Maintenance Management
    [FEATURE_NAME.OVERALL_MAINTENANCE_MANAGEMENT]: "Overall Maintenance Management",
    //Report
    [FEATURE_NAME.ESCALATION_CALL_REPORT]: "Escalation Call Report",
    [FEATURE_NAME.VEHICLE_OVERALL_REPORT]: 'Vehicle Overall Report',
    [FEATURE_NAME.FUEL_ANALYSIS_DASHBOARD]: 'Fuel Analysis Dashboard',
    // App Info
    [FEATURE_NAME.VEHICLE_INFORMATION_PANEL]: 'Vehicle Information Panel',
    [FEATURE_NAME.VEHICLE_ROUTE_PLAYBACK]: 'Vehicle Route Playback',
    [FEATURE_NAME.VEHICLE_EVENT_TRACE]: 'Vehicle Event Trace',
    [FEATURE_NAME.USER_PROFILE]: 'User Profile',
    // Third Party
    [FEATURE_NAME.GREENER_WHEELS]: 'Greener Wheels'
}

const MENU_ICON_SIZE = 24

const MENU_COMPONENT_ITEM = (menu) => (
    {
        key: menu.feature,
        icon: <menu.icon style = {{ fontSize: MENU_ICON_SIZE }} />,
        label: FEATURE_LABEL[menu.feature],
        feature: menu.feature
    }
)

export const FEATURE_MENU_ITEMS = [
    {
        feature: FEATURE_NAME.VEHICLE_INFORMATION_PANEL,
        icon: UnorderedListOutlined
    },
    {
        feature: FEATURE_NAME.VEHICLE_ROUTE_PLAYBACK,
        icon: ForkOutlined
    },
    {
        feature: FEATURE_NAME.GEOFENCE_MANAGEMENT,
        icon: GatewayOutlined
    },
    {
        feature: FEATURE_NAME.USER_MANAGEMENT,
        icon: TeamOutlined
    },
    {
        feature: FEATURE_NAME.HIGHWAY_SURVEILLANCE_MANAGEMENT,
        icon: VideoCameraOutlined,
    },
    {
        feature: FEATURE_NAME.OVERALL_MANAGEMENT,
        icon: SettingOutlined
    },
    {
        feature: FEATURE_NAME.OVERALL_MAINTENANCE_MANAGEMENT,
        icon: InsuranceOutlined
    },
    // {
    //     feature: FEATURE_NAME.VEHICLE_EVENT_TRACE,
    //     icon: SnippetsOutlined
    // },
    {
        feature: FEATURE_NAME.FUEL_ANALYSIS_DASHBOARD,
        icon: AreaChartOutlined
    },
    // {
    //     feature: FEATURE_NAME.ESCALATION_CALL_REPORT,
    //     icon: BellOutlined
    // },
    {
        feature: FEATURE_NAME.VEHICLE_OVERALL_REPORT,
        icon: CopyOutlined
    },
    // Thiry Party
    {
        feature: FEATURE_NAME.GREENER_WHEELS,
        icon: CodepenOutlined
    },
    {
        feature: FEATURE_NAME.USER_PROFILE,
        icon: UserOutlined
    }
]

export const FEATURE_MENU_ITEMS_COMP = FEATURE_MENU_ITEMS.map(menu => MENU_COMPONENT_ITEM(menu))