import 
    React
from 'react';
import { 
    useDispatch, 
    useSelector
} from "react-redux";
import { Tooltip } from "antd";

// Constants
import { 
   MAP
} from '../../../../constants'

// Redux Actions
import { v3_mapControl } from '../../../../services/redux/actions'

// Style
import "./index.css";

const {
    MAP_CONTROL_PANEL_ICON
} = MAP

const {
    clear_enabled_geofence_pop_ups,
    disable_show_live_traffic,
    disable_show_geofences,
    disable_show_geofence_info,
    disable_show_vehicle_info,
    disable_show_highway_suv,
    disable_show_highway_suv_info,
    enable_show_live_traffic,
    enable_show_geofences,
    enable_show_geofence_info,
    enable_show_vehicle_info,
    enable_show_highway_suv,
    enable_show_highway_suv_info,
    autofocus_2_highway_suv
} = v3_mapControl

const ControlPanelToggleButton = (props) => {

    const {
        toggleParams, title, show, enableSRC, disableSRC, onEnable, onDisable
    } = props

    return (
        <>
            {
                toggleParams
                &&
                <Tooltip title={title} placement="left">
                    {
                        show ?
                            <img
                                aria-hidden="true"
                                alt={`Enable_${title}`}
                                className={"icon"}
                                src={enableSRC}
                                onClick={onEnable}
                            /> 
                        :
                            <img
                                aria-hidden="true"
                                alt={`Disable_${title}`}
                                className={"icon"}
                                src={disableSRC}
                                onClick={onDisable}
                            />
                    }
                </Tooltip>
            }
        </>
    )
}

const ControlPanelSUVResource = (props) => {

    const dispatch = useDispatch()

    const UIControl = useSelector(state => state.containers.v3.mapControl.UIControl)

    const {
        SUVResourceToggleVisible = false,
        SUVInfoToggle,
        SUVMakerToggle,
        SUVAutoFocusToggle
    } = props

    return (
        <>
        {
            SUVResourceToggleVisible
            &&
            (
                <>
                    <ControlPanelToggleButton
                        toggleParams = {SUVMakerToggle}
                        title = {"Toggle Highway SUV"}
                        show = {UIControl.showHighwaySUV || false}
                        enableSRC = {MAP_CONTROL_PANEL_ICON.SVG_HIGHWAY_SUV_MARKER_AVAILABLE}
                        disableSRC = {MAP_CONTROL_PANEL_ICON.SVG_HIGHWAY_SUV_MARKER_UNAVAILABLE}
                        onEnable = {() => {
                            dispatch(disable_show_highway_suv());
                        }}
                        onDisable = {() => {
                            dispatch(enable_show_highway_suv());
                        }}
                    />
                    <ControlPanelToggleButton
                        toggleParams = {SUVInfoToggle}
                        title = {"Toggle Highway Info SUV"}
                        show = {UIControl.showHighwayInfoSUV || false}
                        enableSRC = {MAP_CONTROL_PANEL_ICON.SVG_HIGHWAY_SUV_MARKER_LABEL_AVAILABLE}
                        disableSRC = {MAP_CONTROL_PANEL_ICON.SVG_HIGHWAY_SUV_MARKER_LABEL_UNAVAILABLE}
                        onEnable = {() => {
                            dispatch(disable_show_highway_suv_info());
                        }}
                        onDisable = {() => {
                            dispatch(enable_show_highway_suv_info());
                        }}
                    />
                    <ControlPanelToggleButton
                        toggleParams = {SUVAutoFocusToggle}
                        title = {"Auto Focus Highway SUV"}
                        show = {UIControl.autoFocusHighwaySUV || false}
                        enableSRC = {MAP_CONTROL_PANEL_ICON.SVG_HIGHWAY_SUV_AUTOFOCUS}
                        disableSRC = {MAP_CONTROL_PANEL_ICON.SVG_HIGHWAY_SUV_AUTOFOCUS}
                        onEnable = {() => {
                            dispatch(autofocus_2_highway_suv());
                        }}
                        onDisable = {() => {
                            dispatch(autofocus_2_highway_suv());
                        }}
                    />
                </>
            )
            ||
            <></>
        }
        </>
    )
}

const MapControlPanel = (props) => {

    const dispatch = useDispatch()

    const UIControl = useSelector(state => state.containers.v3.mapControl.UIControl)

    const {
        geofenceMarkerToggle,
        geofenceInfoToggle,
        liveTrafficTroggle,
        vehicleInfoToggle
    } = props

    return (
        <div
            style = {{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                height: "100%",
            }}
        >
            <ControlPanelSUVResource {...props}/>
            <ControlPanelToggleButton
                toggleParams = {geofenceMarkerToggle}
                title = {"Toggle Geofences"}
                show = {UIControl.showAllGeofence  || false}
                enableSRC = {MAP_CONTROL_PANEL_ICON.SVG_GEOFENCE_MAKER_AVAILABLE}
                disableSRC = {MAP_CONTROL_PANEL_ICON.SVG_GEOFENCE_MAKER_UNAVAILABLE}
                onEnable = {() => {
                    dispatch(disable_show_geofences());
                }}
                onDisable = {() => {
                    dispatch(enable_show_geofences());
                }}
            />
            <ControlPanelToggleButton
                toggleParams = {geofenceInfoToggle}
                title = {"Toggle Geofence Info"}
                show = {UIControl.showAllGeofencesInfoWindow || false}
                enableSRC = {MAP_CONTROL_PANEL_ICON.SVG_GEOFENCE_MAKER_INFOBOX_AVAILABLE}
                disableSRC = {MAP_CONTROL_PANEL_ICON.SVG_GEOFENCE_MAKER_INFOBOX_UNAVAILABLE}
                onEnable = {() => {
                    dispatch(disable_show_geofence_info());
                }}
                onDisable = {() => {
                    dispatch(enable_show_geofence_info());
                    dispatch(clear_enabled_geofence_pop_ups());
                }}
            />
            <ControlPanelToggleButton
                toggleParams = {vehicleInfoToggle}
                title = {"Toggle Vehicle Info"}
                show = {UIControl.showAllVehiclesInfoWindow || false}
                enableSRC = {MAP_CONTROL_PANEL_ICON.SVG_VEHICLE_MAKER_INFOBOX_AVAILABLE}
                disableSRC = {MAP_CONTROL_PANEL_ICON.SVG_VEHICLE_MAKER_INFOBOX_UNAVAILABLE}
                onEnable = {() => {
                    dispatch(disable_show_vehicle_info());
                }}
                onDisable = {() => {
                    dispatch(enable_show_vehicle_info());
                }}
            />
            <ControlPanelToggleButton
                toggleParams = {liveTrafficTroggle}
                title = {"Toggle Live Traffic"}
                show = {UIControl.showLiveTraffic || false}
                enableSRC = {MAP_CONTROL_PANEL_ICON.SVG_LIVE_TRAFFIC_AVAILABLE}
                disableSRC = {MAP_CONTROL_PANEL_ICON.SVG_LIVE_TRAFFIC_UNAVAILABLE}
                onEnable = {() => {
                    dispatch(disable_show_live_traffic());
                }}
                onDisable = {() => {
                    dispatch(enable_show_live_traffic());
                }}
            />
        </div>
    );
}

export default React.memo(MapControlPanel);