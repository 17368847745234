import dayjs from 'dayjs'
import { format as FNS_format } from 'date-fns'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(utc)

export const referenceStartTime = () => dayjs().startOf('day')
export const referenceEndTime = () => dayjs().add(1, 'days').startOf('day')
export const referenceEndOfDay = () => dayjs().endOf('day')

export const dayjsDatetimeTranslate = (time) => {
    if (isNaN(time)) return `UNKNOWN`
    return dayjs(Number(time)).format("YYYY/MM/DD HH:mm:ss");
} 

export const sanityCheckNumber = (number) => {
    return !isNaN(number) && number !== null && !isNaN(Number(number));
}

export const durationFormat = (duration) => {

    if (isNaN(duration)) return `N/A`

    const dayjsDuration = dayjs.duration(duration);

    const days = parseInt(dayjsDuration.asDays(), 10);
    const hrs = dayjsDuration.hours();
    const mins = dayjsDuration.minutes();
    const secs = dayjsDuration.seconds();

    return (
        days ?
            `${days} days ${hrs} hrs ${mins} mins` :
            hrs ?
                `${hrs} hrs ${mins} mins` :
                mins ?
                    `${mins} mins ${secs} secs` :
                    `${secs} secs`
    )
}

export const parseTime = time => {
    if (!time) return '-'
    return dayjs(Number(time)).format("YYYY/MM/DD HH:mm:ss");
}

export const parseTimeFNS = time => {
    if (!time) return '-'
    return FNS_format(time, "yyyy-MM-dd HH:mm:ss");
}

export const parseHours = millisec => {
    if (isNaN(millisec) && !millisec) return 0
    return millisec/(60*60*1000);
}

export const parseDate = (millisec) => {
    if (millisec === null || isNaN(Number(millisec))) return '-';

    return dayjs(millisec).format('YYYY/MM/DD');
}

export const parseDuration = (millisec) => {
    if (!sanityCheckNumber(millisec)) { return "-" }

    let seconds = (millisec / 1000).toFixed(0);
    let minutes = Math.floor(seconds / 60);
    let hours = 0;

    if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        minutes = minutes - hours * 60;
    }

    seconds = Math.floor(seconds % 60);

    if (Number.isNaN(millisec)) {
        return " Not Available "
    }
    else if (hours > 0) {
        return `${hours} ${hours > 1 ? "hrs" : "hr"} ${minutes} ${minutes > 1 ? "mins" : "min"}`.trim()
    }
    else return `${minutes} ${minutes > 1 ? "mins" : "min"} ${seconds} ${seconds > 1 ? "secs" : "sec"}`.trim()
}

export const parseDurationHRS = (hrs) => {
    if (!sanityCheckNumber(hrs)) { return "-" }

    const millisec = hrs * 60 * 60 * 1000

    let seconds = (millisec / 1000).toFixed(0);
    let minutes = Math.floor(seconds / 60);
    let hours = 0;

    if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        minutes = minutes - hours * 60;
    }

    seconds = Math.floor(seconds % 60);

    if (Number.isNaN(millisec)) {
        return " Not Available "
    }
    else if (hours > 0) {
        return `${hours} ${hours > 1 ? "hrs" : "hr"} ${minutes} ${minutes > 1 ? "mins" : "min"}`.trim()
    }
    else return `${minutes} ${minutes > 1 ? "mins" : "min"} ${seconds} ${seconds > 1 ? "secs" : "sec"}`.trim()
}

export const timeDiff = (startTime, endTime) => {
    const duration = parseDuration(dayjs(Number(endTime)).utc().diff(dayjs(startTime).utc()));

    return duration;
}

export const dayDiffABS = (startTime, endTime) => {
    return Math.abs(dayjs.duration(startTime.diff(endTime)).asDays())
}

export const dayJSLocalize = (timeDayJS) => dayjs(timeDayJS.utc().valueOf())

export const isDayJS = (time) => dayjs.isDayjs(time)