import React from "react";
import PropTypes from "prop-types";
import { Layout as LayoutAntd } from "antd";
import Sidebar from "./Sidebar";

const Layout = ({
  sidebarItems = [],
  onClickSidebarItem = () => {},
  selectedkeys,
  themeColor,
  children
}) => {

  return (
    <LayoutAntd>
      <Sidebar
        items={sidebarItems}
        onClick={onClickSidebarItem}
        selectedkeys={selectedkeys}
        themeColor={themeColor}
      />
      <LayoutAntd>{children}</LayoutAntd>
    </LayoutAntd>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
  sidebarItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onClickSidebarItem: PropTypes.func,
  selectedkeys: PropTypes.string,
  themeColor: PropTypes.string
};

export default Layout;
