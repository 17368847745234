import * as API_CONSTANT from '../constants'

export const TMPImmoVehicleByVID = (vid, immoAction) => (
    fetch(`${API_CONSTANT.fms_v3_fe_path}/tmp/vehicle/immo`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            vid,
            immoAction: immoAction
        })
    })
    .then(res => res.json())
)