import { batch } from "react-redux";
import * as API from "../../api"
import * as ActionTypes from "../action-types";
import { REDUX } from '../../../constants'

/**
 * ThirdParty Library
 */
 import {
    message
} from 'antd'

// Redux Action
import { 
    clear_selected_vehicle_for_transit_record_request,
    set_selected_vehicle_for_transit_record_request
 } from './transitRecords'
import { 
    clear_selected_vehicle_for_trip_record_request,
    set_selected_vehicle_for_trip_record_request 
} from './tripRecords'
import { 
    clear_selected_vehicle_for_vehicle_log_request,
    set_selected_vehicle_for_vehicle_log_request 
} from './vehicleLogs'

// Services
import { subscribeWS } from '../../websocket'

/**
 * Get Vehicle
 */

export const get_vehicle_request = (vid, uid) => dispatch => {
    

    API
    .getVehicleInfoByVID(vid)
    .then(info => {
        
        try {
            const {
                vehicleProfile,
                vehicleStatics,
                vehicleState,
                vehicleDerivativeState,
                vehicleGeoState,
                vehicleFuelProfile,
                vehiclePackageProfile
            } = info

            dispatch(get_vehicle_success(vehicleStatics,vehicleState, vehicleProfile,  vehicleDerivativeState, vehicleGeoState, vehicleFuelProfile, vehiclePackageProfile))

            subscribeWS(
                [vid],
                uid
            )

        } catch(err) {
            message.error('Error in getting vehicles. Refresh page?')
        }

    })
}

const get_vehicle_success = (vehicleStatics,vehicleState, vehicleProfile,  vehicleDerivativeState, vehicleGeoState, vehicleFuelProfile, vehiclePackageProfile) => {
    return {
        type: ActionTypes.GET_VEHICLE,
        vehicleStatics,
        vehicleState,
        vehicleProfile,  
        vehicleDerivativeState,
        vehicleGeoState,
        vehicleFuelProfile, 
        vehiclePackageProfile
    };
}

/**
 * Get Vehicles
 */

 export const get_vehicles_request = (vids, uid) => dispatch => {

    let vidBatch = []

    vids.map((vid, index) => {

        vidBatch.push(vid)

        if( 
            (index+1) % REDUX.REDUX_VEHICLE_BATCH_GET_SIZE === 0
            ||
            index+1 === vids.length
        ) {
            dispatch(batch_get_vehicles_request(vidBatch, uid))
            vidBatch = []
        }

        return null
    })
 }

/**
 * Batch Get Vehicle
 */
 export const batch_get_vehicles_request = (vids, uid) => dispatch => {

    API
    .getVehicleInfoBatch(vids)
    .then(info => {
        
        try {
            const {
                vehicleProfiles,
                vehicleStatics,
                vehicleStates,
                vehicleDerivativeStates,
                vehicleGeoStates,
                vehicleFuelProfiles,
                vehiclePackageProfiles
            } = info

            dispatch(batch_get_vehicles_success(vehicleStatics,vehicleStates, vehicleProfiles,  vehicleDerivativeStates, vehicleGeoStates, vehicleFuelProfiles, vehiclePackageProfiles))

            subscribeWS(
                vids,
                uid
            )


        } catch(err) {
            message.error('Error in batch getting vehicles. Refresh page?')
            console.log('Error', err)
        }

    })
}

const batch_get_vehicles_success = (vehicleStatics,vehicleStates, vehicleProfiles,  vehicleDerivativeStates, vehicleGeoStates, vehicleFuelProfiles, vehiclePackageProfiles) => {
    return {
        type: ActionTypes.GET_VEHICLES,
        vehicleStatics,
        vehicleStates,
        vehicleProfiles,  
        vehicleDerivativeStates,
        vehicleGeoStates,
        vehicleFuelProfiles, 
        vehiclePackageProfiles
    };
}

/**
 * Set Selected Vehicle
 */
export const set_selected_vehicle_request = (vid) => dispatch =>  {
    dispatch(set_selected_vehicle_success(vid))
}

const set_selected_vehicle_success = (vid) => {
    return {
        type: ActionTypes.SET_SELECTED_VEHICLE,
        vid
    }
}

/**
 * Clear Selected Vehicle
 */
export const clear_selected_vehicle_request = () => dispatch => {
    dispatch(clear_selected_vehicle_success())
}

const clear_selected_vehicle_success = () => {
    return {
        type: ActionTypes.CLEAR_SELECTED_VEHICLE
    }
}

// Set Select Vehicle for Route Playback
export const set_selected_vehicle_for_route_playback_request = (vid) => dispatch => {
    batch(() => {
        dispatch(set_selected_vehicle_request(vid))
        dispatch(set_selected_vehicle_for_vehicle_log_request(vid))
        dispatch(set_selected_vehicle_for_transit_record_request(vid))
        dispatch(set_selected_vehicle_for_trip_record_request(vid))
    })
}

export const clear_selected_vehicle_for_route_playback_request = () => dispatch => {
    batch(() => {
        dispatch(clear_selected_vehicle_request())
        dispatch(clear_selected_vehicle_for_transit_record_request())
        dispatch(clear_selected_vehicle_for_trip_record_request())
        dispatch(clear_selected_vehicle_for_vehicle_log_request())
    })
}

// Update Vehicle thru Websocket

export const update_vehicle_info_thru_ws_request = (vid, vehicle) => dispatch => {
    dispatch(update_vehicle_info_thru_ws_success(vid, vehicle))
}

const update_vehicle_info_thru_ws_success = (vid, vehicle) => {
    return {
        type: ActionTypes.UPDATE_VEHICLE_INFO_THRU_WS,
        vid,
        vehicle
    };
}

export const update_vehicle_geo_state_thru_ws_request = (vid, vehicle) => dispatch => {
    dispatch(update_vehicle_geo_state_thru_ws_success(vid, vehicle))
}

const update_vehicle_geo_state_thru_ws_success = (vid, vehicle) => {
    return {
        type: ActionTypes.UPDATE_VEHICLE_GEO_STATE_THRU_WS,
        vid,
        vehicle
    };
}

export const update_vehicle_state_info = (vid, vehicle) => dispatch => {
    dispatch(update_vehicle_state_info_success(vid, vehicle))
}

const update_vehicle_state_info_success = (vid, vehicle) => {
    return {
        type: ActionTypes.UPDATE_VEHICLE_STATE_INFO,
        vid,
        vehicle
    };
}