import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Tooltip } from 'antd'
import { PoweroffOutlined } from '@ant-design/icons';

// Redux
import * as REDUX_ACTION from '../../../../services/redux/actions'

const MobilizerBTN = (props) => {

    const dispatch = useDispatch()

    const {
        immo,
        vid
    } = props

    return (
        <>
            {
                immo ?
                (
                    <Tooltip
                        placement="top" 
                        title={'Mobilize the vehicle'}
                    >
                        <Button 
                            icon={<PoweroffOutlined />} shape="circle"
                            onClick={() => dispatch(REDUX_ACTION.v3_vehicleCC.TMP_immo_vehicle_request(vid, false))}
                        />
                    </Tooltip>
                )
                :
                (
                    <Tooltip
                        placement="top" 
                        title={'Immobilize the vehicle'}
                    >
                        <Button 
                            icon={<PoweroffOutlined />} shape="circle" type="primary" danger
                            onClick={() => dispatch(REDUX_ACTION.v3_vehicleCC.TMP_immo_vehicle_request(vid, true))}
                        />
                    </Tooltip>
                )
            }
        </>
    )
}


export default MobilizerBTN