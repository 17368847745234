import 
    React,
    {
        useEffect
    } 
from 'react'
import {
    useDispatch
} from 'react-redux'

// Constant
import { TEMPLATE } from '../../constants'

// Components
import * as BTNotify from './components/BTNotification'

// Services
import { wsContainer } from '../../services/websocket'

// Redux Action
import { v3_vehicles } from '../../services/redux/actions'

const {
    PACKAGE
} = TEMPLATE

const {
    WS_PROCESS_PACKAGE
} = PACKAGE

const {
    update_vehicle_info_thru_ws_request,
    update_vehicle_geo_state_thru_ws_request
} = v3_vehicles

const BackgroundTask = () => {

    const dispatch = useDispatch()

    // Websocket
    useEffect(() => {

        wsContainer(
            (err, data) => {

                if(!err) {
                    const {
                        id: refID,
                        mode,
                        item
                    } = data

                    switch(mode) {
                        case WS_PROCESS_PACKAGE.GEO: {
                            const vid = refID

                            dispatch(update_vehicle_geo_state_thru_ws_request(vid, item))

                            break;
                        }
                        case WS_PROCESS_PACKAGE.VHC: {
                            const vid = refID
                            
                            dispatch(update_vehicle_info_thru_ws_request(vid, item))

                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
                
            },
            () => {
                // resubscribe
            }
        )

    })

    return (
        <></>
    )
}

export default BackgroundTask

export {
    BTNotify
}