import * as API from "../../api"

/**
 * ThirdParty Library
 */
 import {
    message
} from 'antd'

// Components
import { BTNotify } from '../../../components/BackgroundTask'

// Redux Action
import { 
    update_vehicle_state_info
 } from './vehicles'

// Services

/**
 * Get Vehicle
 */

export const TMP_immo_vehicle_request = (vid, immoAction) => dispatch => {

    API
    .TMPImmoVehicleByVID(vid, immoAction)
    .then(info => {
        try {
            
            const {
                status,
                msg
            } = info

            if(status === 200) {
                const conveyMSG = `${immoAction && 'Immobilizer' || 'Mobilizer'} the vehicle successfully`
                message.info(conveyMSG)
                BTNotify.NotifyInfo('Mobilize Action', conveyMSG)
                dispatch(update_vehicle_state_info(vid, {vid, immo:immoAction}))
            } else {
                BTNotify.NotifyWarn('Mobilize Action', msg)
                message.error(msg)
            }

        } catch(err) {
            console.log(err)
            message.error('Error to send command to vehicle. Refresh page?')
        }
    })
}