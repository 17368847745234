
import { FEATURE_NAME } from '../common/features'

const MODULE_ROUTE = {
    // Management
    [FEATURE_NAME.GEOFENCE_MANAGEMENT]: `/management/geofence`,
    [FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_CREATE]: `/management/geofence/zone/create`,
    [FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_EDIT]: `/management/geofence/zone/edit`,
    [FEATURE_NAME.GEOFENCE_MANAGEMENT_POI_CREATE]: `/management/geofence/poi/create`,
    [FEATURE_NAME.GEOFENCE_MANAGEMENT_POT_EDIT]: `/management/geofence/poi/edit`,
    [FEATURE_NAME.USER_MANAGEMENT]: `/management/user`,
    [FEATURE_NAME.OVERALL_MANAGEMENT]: `/management/overall`,
    [FEATURE_NAME.DEVICE_MANAGEMENT]: `/management/device`, 
    [FEATURE_NAME.VEHICLE_MANAGEMENT]: `/management/vehicle`, 
    [FEATURE_NAME.VEHICLE_GROUP_MANAGEMENT]: `/management/vehicleGroup`,
    [FEATURE_NAME.RULE_ENGINE_MANAGEMENT]: `/management/rule`, 
    [FEATURE_NAME.NOTIFICATION_MANAGEMENT]: '/management/notification',
    // Highway Surveillance Management
    [FEATURE_NAME.HIGHWAY_SURVEILLANCE_MANAGEMENT]: '/management/surveillance',
    // Maintenance Management
    [FEATURE_NAME.OVERALL_MAINTENANCE_MANAGEMENT]: '/maintenance/overall',
    [FEATURE_NAME.OVERALL_MAINTENANCE_STATE]: '/maintenance/state',
    [FEATURE_NAME.MAINTENANCE_PROFILE_MANAGEMENT]: '/maintenance/profile',
    [FEATURE_NAME.MAINTENANCE_CONFIGURATION_MANAGEMENT]: '/maintenance/configuration',
    //Report
    [FEATURE_NAME.ESCALATION_CALL_REPORT]: `/report/escalation_call`,
    [FEATURE_NAME.VEHICLE_OVERALL_REPORT]: `/report/vehicle`,
    [FEATURE_NAME.FUEL_ANALYSIS_DASHBOARD]: `/report/analysis/fuel`,
    // App Info
    [FEATURE_NAME.VEHICLE_INFORMATION_PANEL]: `/info/vehicle`,
    [FEATURE_NAME.VEHICLE_ROUTE_PLAYBACK]: `/info/route/playback`,
    [FEATURE_NAME.VEHICLE_EVENT_TRACE]: `/info/vehicle/event`,
    [FEATURE_NAME.USER_PROFILE]: `/user/profile`,
    [FEATURE_NAME.LANDING_PAGE]: `/`,
    [FEATURE_NAME.USER_HIERARCHY_MAP]: `/user/hieararchy/map`,
    // Thirty Party
    [FEATURE_NAME.GREENER_WHEELS]: `/3p/greener_wheels`
}

/**
 * Map Availability for below route
 */

const MAP_ROUTE = {
    [FEATURE_NAME.LANDING_PAGE] : MODULE_ROUTE[FEATURE_NAME.LANDING_PAGE],
    [FEATURE_NAME.USER_PROFILE]: MODULE_ROUTE[FEATURE_NAME.USER_PROFILE],
    [FEATURE_NAME.VEHICLE_ROUTE_PLAYBACK]: MODULE_ROUTE[FEATURE_NAME.VEHICLE_ROUTE_PLAYBACK],
    [FEATURE_NAME.GEOFENCE_MANAGEMENT]: MODULE_ROUTE[FEATURE_NAME.GEOFENCE_MANAGEMENT],
    [FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_CREATE]: MODULE_ROUTE[FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_CREATE],
    [FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_EDIT]: MODULE_ROUTE[FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_EDIT],
    [FEATURE_NAME.GEOFENCE_MANAGEMENT_POI_CREATE]: MODULE_ROUTE[FEATURE_NAME.GEOFENCE_MANAGEMENT_POI_CREATE],
    [FEATURE_NAME.GEOFENCE_MANAGEMENT_POT_EDIT]: MODULE_ROUTE[FEATURE_NAME.GEOFENCE_MANAGEMENT_POT_EDIT],
    [FEATURE_NAME.USER_PROFILE]: MODULE_ROUTE[FEATURE_NAME.USER_PROFILE]
}

const OVERALL_MANAGEMENT_ROUTE = {
    [FEATURE_NAME.OVERALL_MANAGEMENT]: MODULE_ROUTE[FEATURE_NAME.OVERALL_MANAGEMENT],
    [FEATURE_NAME.VEHICLE_MANAGEMENT]: MODULE_ROUTE[FEATURE_NAME.VEHICLE_MANAGEMENT],
    [FEATURE_NAME.VEHICLE_GROUP_MANAGEMENT]: MODULE_ROUTE[FEATURE_NAME.VEHICLE_GROUP_MANAGEMENT],
    [FEATURE_NAME.DEVICE_MANAGEMENT]: MODULE_ROUTE[FEATURE_NAME.DEVICE_MANAGEMENT],
    [FEATURE_NAME.RULE_ENGINE_MANAGEMENT]: MODULE_ROUTE[FEATURE_NAME.RULE_ENGINE_MANAGEMENT],
    [FEATURE_NAME.NOTIFICATION_MANAGEMENT]: MODULE_ROUTE[FEATURE_NAME.NOTIFICATION_MANAGEMENT]
}

const MAINTENANCE_MANAGEMENT_MODULE_ROUTE = {
    [FEATURE_NAME.OVERALL_MAINTENANCE_MANAGEMENT]: MODULE_ROUTE[FEATURE_NAME.OVERALL_MAINTENANCE_MANAGEMENT],
    [FEATURE_NAME.OVERALL_MAINTENANCE_STATE]: MODULE_ROUTE[FEATURE_NAME.OVERALL_MAINTENANCE_STATE],
    [FEATURE_NAME.MAINTENANCE_PROFILE_MANAGEMENT]:  MODULE_ROUTE[FEATURE_NAME.MAINTENANCE_PROFILE_MANAGEMENT],
    [FEATURE_NAME.MAINTENANCE_CONFIGURATION_MANAGEMENT]:  MODULE_ROUTE[FEATURE_NAME.MAINTENANCE_CONFIGURATION_MANAGEMENT]
}

const SECONDARY_SIDEBAR_COLLAPSIBLE_ROUTE = [
    MODULE_ROUTE[FEATURE_NAME.LANDING_PAGE],
    MODULE_ROUTE[FEATURE_NAME.VEHICLE_ROUTE_PLAYBACK],
    MODULE_ROUTE[FEATURE_NAME.GEOFENCE_MANAGEMENT],
    MODULE_ROUTE[FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_CREATE],
    MODULE_ROUTE[FEATURE_NAME.GEOFENCE_MANAGEMENT_ZONE_EDIT],
    MODULE_ROUTE[FEATURE_NAME.GEOFENCE_MANAGEMENT_POI_CREATE],
    MODULE_ROUTE[FEATURE_NAME.GEOFENCE_MANAGEMENT_POT_EDIT],
    MODULE_ROUTE[FEATURE_NAME.USER_PROFILE]
]

export {
    MAP_ROUTE,
    MODULE_ROUTE,
    OVERALL_MANAGEMENT_ROUTE,
    MAINTENANCE_MANAGEMENT_MODULE_ROUTE,
    SECONDARY_SIDEBAR_COLLAPSIBLE_ROUTE 
}