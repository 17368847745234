import React, { useState } from 'react'
import {
    MarkerF,
    InfoWindow,
} from '@react-google-maps/api';

import { vehicles_handler } from '../../../../handlers'

const {
    locationMarkiser
} = vehicles_handler

const IndicatorMarker = (props) => {

    const {
        location,
        InfoComponent,
        markerProps,
        infoWindowProps,
        infoDefault
    } = props

    const [ indicatorState, setIndicatorMarkerState ] = useState(infoDefault || false)

    const position = locationMarkiser(location)

    return (
        <>
            <MarkerF
                {...markerProps}
                position = {position}
                onClick = {() => setIndicatorMarkerState(!indicatorState)}
                {...markerProps}
            >
                {
                    indicatorState
                    &&
                    <InfoWindow
                        zIndex = {1000}
                        position = {position}
                        options = {{
                            disableAutoPan: true,
                        }}
                        {...infoWindowProps}
                    >
                        <InfoComponent />
                    </InfoWindow>
                }
            </MarkerF>
        </>
    )
}

export default IndicatorMarker