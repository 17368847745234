import { combineReducers } from "redux";

// V3 Reducer
import {devices} from './devices'
import {deviceGroups} from './deviceGroups'
import {geofences} from './geofences'
import {geofenceTemplates} from './geofenceTemplates'
import {maintenanceConfigs} from './maintenanceConfigs'
import {mapControl} from './mapControl'
// export {reportDailySummaries} from './reportDailySummaries'
import {rules} from './rules'
import {ruleTemplates} from './ruleTemplates'
import {statusBar} from './statusBar'
import {style} from './style'
import {subscriptionPackages} from './subscriptionPackages'
import { surveillances } from './surveillances'
import {transitRecords} from './transitRecords'
import {tripRecords} from './tripRecords'
import {user} from './user';
import {userHierarchy} from './userHierarchy'
import {userRoles} from './userRoles'
import {utils} from './utils'
import {vehicles} from './vehicles'
import {vehicleLogs} from './vehicleLogs'
import {vehicleDerivativeLogs} from './vehicleDerivativeLogs'
import {vehicleECULogs} from './vehicleECULogs'
import {vehicleEventLogs} from './vehicleEventLogs'
import {vehicleGeoStateLogs} from './vehicleGeoStateLogs'
import {vehicleGroups} from './vehicleGroups'
import {vehicleMaintenances} from './vehicleMaintenances'
import {vehicleReportDailySummaries} from './vehicleReportDailySummaries'
import {vehicleReportFuelAnalysis} from './vehicleReportFuelAnalysis'

export const v3Reducer = combineReducers({
    devices,
    deviceGroups,
    geofences,
    geofenceTemplates,
    maintenanceConfigs,
    mapControl,
    // reportDailySummaries,
    rules,
    ruleTemplates,
    statusBar,
    user,
    userRoles,
    userHierarchy,
    utils,
    style,
    subscriptionPackages,
    surveillances,
    transitRecords,
    tripRecords,
    vehicles,
    vehicleLogs,
    vehicleDerivativeLogs,
    vehicleECULogs,
    vehicleEventLogs,
    vehicleGeoStateLogs,
    vehicleGroups,
    vehicleMaintenances,
    vehicleReportDailySummaries,
    vehicleReportFuelAnalysis
})