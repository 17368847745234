import * as ActionTypes from '../action-types'

/**
 * Constant
 */
import {
    MAP,
    TEMPLATE
} from '../../../constants'

const {
    ALL_VEHICLE_TRANSIT_STATUS
} = TEMPLATE.STATUS

const MAP_CONTROL_DEFAULT_STATE = {
    geofence: {},
    mapControl: 0,
    UIControl: {
        // Filter stuff
        durationMin: 0,
        selectedTransitTypes: ALL_VEHICLE_TRANSIT_STATUS,

        // info window
        showAllVehiclesInfoWindow: true,
        showAllGeofencesInfoWindow: true,

        // all geofence stuffs
        showAllGeofence: true,

        showInfoWindowEvents: [],
        showInfoWindowVehicles: [],
        showInfoWindowGeofences: [],

        // not used for now
        showLiveTraffic: false,
        showVehicleTrail: true,

        // SUV
        showHighwaySUV: true,
        showHighwayInfoSUV: true,
        autoFocusHighwaySUV: false
    },
    mapEvent: {
        isFocusPoint: false,
        focusDetailPointType: null,
        transitRecordDrawerState: false
    }
}

export const mapControl = (state = MAP_CONTROL_DEFAULT_STATE, action) => {
    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch(action.type) {

        case ActionTypes.RESET_MAP_LOCATION: {
            newState.location = MAP.PREFERENCE_MAP_LOCATION

            return newState;
        }

        case ActionTypes.SET_MAP_LOCATION: {
            newState.location = action.location;

            return newState;
        }

        case ActionTypes.SET_MAP_ZOOM: {
            newState.zoom = action.zoom;

            return newState;
        }

        case ActionTypes.SET_MAP_CONTROL: {
            newState.mapControl = action.control

            return newState;
        }

        case ActionTypes.SET_NEW_GEOFENCE: {
            newState.drawed = true;
            newState.geofence = action.geofence;

            return newState;
        }

        case ActionTypes.CLEAR_GEOFENCE: {
            newState.geofence = {};
            newState.poiCoded = null;

            return newState;
        }

        case ActionTypes.SET_SELECTED_GEOFENCE_TEMPLATES: {
            newState.selectedGeofenceTemplates = action.geofenceTemplateList;

            return newState;
        }

        case ActionTypes.CLEAR_SELECTED_GEOFENCE_TEMPLATES: {
            newState.selectedGeofenceTemplates = [];

            return newState;
        }

        case ActionTypes.ADD_MAP_SHAPE_INTO_LIST: {
            newState.polygonOnMapList.push(action.drawnShape);

            return newState;
        }

        case ActionTypes.CLEAR_MAP_SHAPES_FROM_LIST: {
            newState.polygonOnMapList = []; // Clear array

            return newState;
        }

        case ActionTypes.GET_BROWSER_LOC: {
            newState.location = action.location;

            return newState;
        }

        case ActionTypes.SET_MAP_FILTERS: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    durationMin: action.durationMin,
                    selectedEventTypes: action.selectedEventTypes,
                    selectedTransitTypes: action.selectedTransitTypes,
                }
            };
        }

        case ActionTypes.ADD_ENABLED_VEHICLE_POP_UP: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showInfoWindowVehicles: !newState.UIControl.showInfoWindowVehicles.includes(action.enabledIdentifier) ?
                        [...newState.UIControl.showInfoWindowVehicles, action.enabledIdentifier] :
                        newState.UIControl.showInfoWindowVehicles,
                }
            };
        }

        case ActionTypes.REMOVE_ENABLED_VEHICLE_POP_UP: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showInfoWindowVehicles: newState.UIControl.showInfoWindowVehicles.filter(id=>id!==action.enabledIdentifier)
                }
            };
        }

        case ActionTypes.CLEAR_ENABLED_VEHICLE_POP_UP: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showInfoWindowVehicles: [],
                }
            };
        }

        case ActionTypes.ADD_ENABLED_GEOFENCE_POP_UP: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showInfoWindowGeofences: !newState.UIControl.showInfoWindowGeofences.includes(action.enabledIdentifier) ?
                        [...newState.UIControl.showInfoWindowGeofences, action.enabledIdentifier] :
                        newState.UIControl.showInfoWindowGeofences,
                }
            };
        }

        case ActionTypes.CLEAR_ENABLED_GEOFENCE_POP_UP: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showInfoWindowGeofences: [],
                }
            };
        }

        case ActionTypes.ADD_ENABLED_EVENT_POP_UP: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showInfoWindowEvents: !newState.UIControl.showInfoWindowEvents.includes(action.enabledIdentifier) ?
                        [...newState.UIControl.showInfoWindowEvents, action.enabledIdentifier] :
                        newState.UIControl.showInfoWindowEvents,
                }
            };
        }

        case ActionTypes.REMOVE_ENABLED_EVENT_POP_UP: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showInfoWindowEvents: newState.UIControl.showInfoWindowEvents.filter(id=>id!==action.enabledIdentifier)
                }
            };
        }

        case ActionTypes.CLEAR_ENABLED_EVENT_POP_UPS: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showInfoWindowEvents: [],
                }
            };
        }

        case ActionTypes.SET_NEW_GEOFENCE_FILTER_STRING: {
            return {
                ...newState,
                geofenceFilterString: action.newFilterString,
            };
        }

        case ActionTypes.RESET_GEOFENCE_FILTER_STRING: {
            return {
                ...newState,
                geofenceFilterString: MAP_CONTROL_DEFAULT_STATE.geofenceFilterString,
            };
        }

        case ActionTypes.ENABLE_SHOW_VEHICLE_INFO: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showAllVehiclesInfoWindow: true,
                }
            };
        }

        case ActionTypes.DISABLE_SHOW_VEHICLE_INFO: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showAllVehiclesInfoWindow: false,
                }
            };
        }

        case ActionTypes.ENABLE_SHOW_GEOFENCE_INFO: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showAllGeofencesInfoWindow: true,
                }
            };
        }

        case ActionTypes.DISABLE_SHOW_GEOFENCE_INFO: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showAllGeofencesInfoWindow: false,
                }
            };
        }

        case ActionTypes.ENABLE_SHOW_GEOFENCES: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showAllGeofence: true,
                }
            };
        }

        case ActionTypes.DISABLE_SHOW_GEOFENCES: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showAllGeofence: false,
                }
            };
        }

        case ActionTypes.ENABLE_SHOW_LIVE_TRAFFIC: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showLiveTraffic: true,
                }
            };
        }

        case ActionTypes.DISABLE_SHOW_LIVE_TRAFFIC: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showLiveTraffic: false,
                }
            };
        }

        case ActionTypes.ENABLE_SHOW_VEHICLE_TRAIL: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showVehicleTrail: true,
                }
            };
        }

        case ActionTypes.DISABLE_SHOW_VEHICLE_TRAIL: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showVehicleTrail: false,
                }
            };
        }

        case ActionTypes.ENABLE_SHOW_HIGHWAY_SUV: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showHighwaySUV: true,
                }
            };
        }

        case ActionTypes.DISABLE_SHOW_HIGHWAY_SUV: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showHighwaySUV: false,
                }
            };
        }

        case ActionTypes.ENABLE_SHOW_HIGHWAY_SUV_INFO: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showHighwayInfoSUV: true,
                }
            };
        }

        case ActionTypes.DISABLE_SHOW_HIGHWAY_SUV_INFO: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    showHighwayInfoSUV: false,
                }
            };
        }

        case ActionTypes.AUTOFOCUS_HIGHWAY_SUV: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    autoFocusHighwaySUV: true,
                }
            };
        }

        case ActionTypes.DISABLE_AUTOFOCUS_HIGHWAY_SUV: {
            return {
                ...newState,
                UIControl: {
                    ...newState.UIControl,
                    autoFocusHighwaySUV: false,
                }
            };
        }

        case ActionTypes.RESET_UI_CONTROL: {
            return {
                ...newState,
                UIControl: MAP_CONTROL_DEFAULT_STATE.UIControl
            };
        }

        // Map Event

        case ActionTypes.SET_MAP_FOCUS_DETAIL_POINT_TYPE: {

            newState.mapEvent.focusDetailPointType = action.pointType

            return newState
        }

        case ActionTypes.FOCUS_ON_MAP_DETAIL_POINT: {

            newState.mapEvent.isFocusPoint = true

            return newState
        }

        case ActionTypes.UNFOCUS_ON_MAP_DETAIL_POINT: {

            newState.mapEvent.isFocusPoint = false

            return newState
        }

        case ActionTypes.OPEN_TRANSIT_DRAWER_ON_MAP: {

            newState.mapEvent.transitRecordDrawerState = true

            return newState
        }

        case ActionTypes.CLOSE_TRANSIT_DRAWER_ON_MAP: {

            newState.mapEvent.transitRecordDrawerState = false

            return newState
        }

        default: {
            return newState;
        }
    }
}