export const MILEAGE_TYPE = {
    'ODOMETER': 'ODOMETER',
    'TRIP_MILEAGE': 'TRIP_MILEAGE'
}

export const FUEL_TYPE = {
    'LEVEL': 'LEVEL',
    'CAPACITY': 'CAPACITY'
}

export const DERIVATIVE_DATA_TYPE = {
    'HISTORICAL': 'HISTORICAL',
    'TRIP': 'TRIP',
    'STATE': 'STATE'
}

export const mileageKMConvertor = (mileage) => mileage/1000

export const mileageSelector = (packet, type, last_data = 1) => {
    let mileage = 0

    const has_ecu = packet.has_ecu
    const has_ecu_can = packet.has_ecu_can
    const has_ecu_obd = packet.has_ecu_obd
    const has_ecu_obd_oem = packet.has_ecu_obd_oem

    const ecu_sel = (has_ecu || has_ecu_can || has_ecu_obd || has_ecu_obd_oem) && 1 || 0

    const ecu_odometer = packet.ecu_odometer || packet.ecu_can_odometer || packet.ecu_obd_oem_odometer

    switch (type) {
        case MILEAGE_TYPE.ODOMETER: {
            mileage = ecu_sel && last_data && ecu_odometer || packet.plat_hist_mileage
            break;
        }
        case MILEAGE_TYPE.TRIP_MILEAGE: {
            mileage = ecu_sel && last_data && packet.ecu_total_mileage || packet.plat_total_mileage
            break;
        }
        default: {
            break;
        }
    }

    return mileageKMConvertor(mileage)
}

export const speedSelector = (packet, type) => {
    let speed = 0

    const has_ecu = packet.has_ecu
    const has_ecu_can = packet.has_ecu_can
    const has_ecu_obd = packet.has_ecu_obd
    const has_ecu_obd_oem = packet.has_ecu_obd_oem

    const ecu_sel = (has_ecu || has_ecu_can || has_ecu_obd || has_ecu_obd_oem) && 1 || 0

    switch (type) {
        case DERIVATIVE_DATA_TYPE.HISTORICAL: {
            speed = ecu_sel && packet.ecu_hist_top_speed || packet.plat_hist_top_speed
            break;
        }
        case DERIVATIVE_DATA_TYPE.TRIP: {
            speed = ecu_sel && packet.ecu_speed_top || packet.plat_speed_top
            break;
        }
        case DERIVATIVE_DATA_TYPE.STATE: {
            speed = ecu_sel && packet.ecu_speed || packet.speed
            break;
        }
        default: {
            break;
        }
    }

    return speed || 0
}

export const fuelSelector = (VHCDs, vhcFuelProfile, n, type) => {

    let fuel = 0

    const vehicleLevel = (VHCDs && vhcFuelProfile && VHCDs[vhcFuelProfile[`${n}_deviceParam`]]) || 0
    const vfMax = (vhcFuelProfile && vhcFuelProfile [`${n}_max`]) || 0
    const vfMin = (vhcFuelProfile && vhcFuelProfile [`${n}_min`]) || 0
    const fuelLevel = Number((((vehicleLevel-vfMin) / (vfMax-vfMin)) * 100).toFixed(2))
    const fuelCapacity = [vhcFuelProfile[`${n}_capacity`]] || 0

    switch(type) {
        case FUEL_TYPE.CAPACITY: {
            fuel = fuelLevel / 100 * fuelCapacity
            break;
        }
        case FUEL_TYPE.LEVEL: {
            fuel = fuelLevel
            break;
        }
        default: {
            break;
        }
    }

    return fuel
}